import React from 'react';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import { Icon, IconType, Checkbox } from '@dealroadshow/uikit';
import { useCookiesBannerContext } from './CookiesBannerContext';

import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';

import styles from './cookiesBanner.scss';

/**
 * @deprecated This component has been chosen for migration to UIKit, meaning any further changes or updates
 * must be completed within the migration process.
 * @see https://www.notion.so/finsight-group/Component-Migration-Process-f4475950481d429ba0dc450d0bb0cb8b
 */
const CookiesBanner = () => {
  const {
    isMarketingAccepted,
    isManageCookiesOpen,
    isBannerVisible,
    onMarketingChange,
    openCookiesSettings,
    closeBanner,
    saveAll,
  } = useCookiesBannerContext();

  if (!isBannerVisible) {
    return null;
  }
  return (
    <div className={ styles.cookiesBannerWrapper }>
      <Icon
        className={ styles.cookiesBannerClose }
        type={ IconType.close }
        onClick={ isManageCookiesOpen ? closeBanner : saveAll }
        data-test="closeCookieBannerIcon"
      />
      <div className={ styles.cookiesBannerContent }>
        <p className={ styles.cookiesBannerText }>
          We use functional cookies to make this site work properly and marketing cookies to personalize content.
          By clicking { isManageCookiesOpen ? '"Save"' : '"Ok"' }, you consent to our use of cookies. See our&nbsp;
          <a
            className={ styles.cookiesBannerLink }
            href={ `${ finsightWebUrl.getPrivacyPolicyUrl() }#cookiesPolicy` }
            target="_blank"
          >
            Cookie Policy
          </a>&nbsp;for more information.
        </p>
        <Button
          variant={ variantTypes.action }
          dataTest={ isManageCookiesOpen ? 'saveCookiesSettings' : 'agreeWithCookiesBtn' }
          onClick={ isManageCookiesOpen ? closeBanner : saveAll }
          className={ styles.cookiesBannerBtn }
          title={ isManageCookiesOpen ? 'Save' : 'Ok' }
        />
        <div className={ styles.cookiesManageBlock }>
          { !isManageCookiesOpen ? (
            <Button
              variant={ variantTypes.outline }
              dataTest="manageCookiesBtn"
              onClick={ openCookiesSettings }
              className={ styles.cookiesBannerManageBtn }
            >
              Manage
            </Button>
          ) : (
            <div className={ styles.cookiesCheckboxes }>
              <Checkbox
                dataTest="functionalCookiesAccept"
                label="Functional"
                disabled
                checked
                className={ styles.functionalCheckbox }
              />
              <Checkbox
                dataTest="marketingCookiesAccept"
                label={ <label className={ styles.marketingLabel }>Marketing</label> }
                onChange={ onMarketingChange }
                checked={ isMarketingAccepted }
                className={ styles.marketingCheckbox }
              />
            </div>
          ) }
        </div>
      </div>
    </div>
  );
};

export default CookiesBanner;
